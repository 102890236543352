import * as React from 'react'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby-link'

// COMPONENTS
import ClosablePill from 'components/styled/pill'
import { Button } from 'components/styled/button'
import { Card } from 'components/styled/card'
import { Input } from 'components/styled/input'
import { SCREEN } from 'styles/screens'
import { FixedContainer } from 'components/styled/containers'
import Dropdown from '../styled/dropdown'

// ASSETS
import _CameraIcon from 'svgs/camera.svg'
// import _ProfilePicIcon from 'svgs/profilepic.svg'
import ShieldGreen from 'svgs/sub_true.svg'
import ShieldRed from 'svgs/sub_false.svg'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import PhoneNumberInput from '../styled/phoneNumberInput'
import Modal from 'react-modal'
import { Tab, Tabs } from '../styled/tabs'
import axios from 'axios'
import { config } from '../../config'
import { PHONE_NUMBER_CODES } from '../../data/phoneCodes'
import ChangePassword from '../changePassword/changePassword'
import CancelSubscription from '../subscription/cancelSubscription'
import SubscriptionCard from '../subscription/subscriptionCard'
import { DEFAULT_PROFILE_IMAGE, getOptions, getTranslation } from '../../data/dictionary'
import UpdateSubscription from '../updateSubscription/updateSubscription'
import Api from '../../helpers/api'

const CameraIcon = styled(_CameraIcon)`
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
`

// const ProfilePicIcon = styled(_ProfilePicIcon)``

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;

  .css-wmg0qy-control {
    margin-left: 15px;
  }

  .custom-link {
    color: inherit;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px 0;
  }
`

const HeadingsContainer = styled.div`
  align-self: flex-start;
  max-width: 35%;
  flex: 1 1 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  button {
    width: 100%;

  }

  .mt-60px {
    margin-top: 60px;
  }
  .mt-20px {
    margin-top: 20px;
  }

  > div:last-child {
    margin-top: 96px;

    h6 {
      font-weight: 500;
    }

    .custom-link {
      font-size: 24px;
    }

    > button, .custom-link {
      svg {
        margin-left: 12px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: none;
  }
`

const MobileHeadingsContainer = styled.div`
  display: none;
}

@media only screen and (max-width: ${SCREEN.MOBILE}) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  max-width: 100%;
  flex: 1 1 100%;
  align-items: flex-start;
  padding: 0 16px;
  h2 {
    line-height: 80px;
    font-size: 70px;
  }

  > div:last-child {
    margin-top: 12px;
    display: flex;
    flex-direction: column;


    h6 {
      font-weight: 500;
      margin: 0;
      font-size: 17px;

      svg {
        height: 12px;
      }
    }

    small {
      font-size: 16px;
      margin: 8px 0;
    }

    > button {
      // font-size: 12px;
      // padding: 8px;
      // border-radius: calc(var(--border-radius) / 2);

      svg {
        margin-left: 12px;
        height: 8px;
      }
    }
  }
}
`

const StyledH2 = styled.h2`
  line-height: 80px;
  margin: 0;
  letter-spacing: 0;
  color: ${({ inverted }) => (inverted ? 'transparent' : 'var(--primary)')};
  -webkit-text-stroke: 2.7px var(--primary);
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    -webkit-text-stroke: 1.3px var(--primary);
    font-size: 1.8rem;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 40px 48px 18px 48px;
  flex: 0 0 60%;
  max-width: 60%;

  > h6 {
    font-weight: 500;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: none;
  }
`

const MobileStyledCard = styled.div`
  display: none;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: unset;
    padding: 0 16px 18px;
    > h6 {
      font-weight: 500;
    }
  }
`

const FormHeading = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 24px;

  h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    cursor: pointer;
    background-color: var(--primary-lighter);
    border: unset;
    padding: 8px 12px;
    margin: 0 8px;
    font-size: 16px;
    border-radius: calc(var(--border-radius) / 2);

    &:hover {
      background-color: var(--secondary-light);
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: none;
  }
`

const MobileFormHeading = styled.div`
  display: none;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
  }
`

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const MobileFormBody = styled.div`
  display: none;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 18px 0;
    > div > div {
      max-width: 100%;
      flex: 0 0 100%;
    }

    > div > div:nth-child(7) {
      max-width: 100%;
      margin: 0 12px 24px 0;
      flex: 1 1 100%;
    }

    > div > div:nth-child(8) {
      max-width: 100%;
      margin: 0;
      flex: 1 1 100%;
    }
  }
`

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 0 0 100%;
  max-width: 100%;

  > div {
    margin: 12px 0 24px 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }

  // > div:nth-child(2n) {
  //   margin: 0 0 24px 0;
  // }

  > div:nth-child(7) {
    max-width: unset;
    margin: 24px 0 24px 0;
    flex: 1 1 100%;
  }

  > div:nth-child(8) {
    max-width: unset;
    margin: 0;
    flex: 1 1 100%;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > div:nth-child(2n) {
      margin: 0 12px 24px 0;
    }

    > div:nth-child(7) {
      max-width: 340px;
      margin: 0 12px 24px 0;
      flex: 1 1 100%;
    }

    > div:nth-child(8) {
      max-width: 340px;
      margin: 0;
      flex: 1 1 100%;
    }
  }
`
const ProfilePicture = styled.div`
  position: relative;
  max-width: 120px;
  width: 100%;
  margin-bottom: 12px;
  margin-left: 15px;
  margin-right: auto;
  margin-top: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  > svg {
    cursor: pointer;
  }

  img {
    max-height: 120px;
    //border: solid 1px black;
    border-radius: 100%;
    height: 100%;
    width: 100%;
  }
`

const MobileProfileImageContainer = styled.div`
  display: none;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    flex-direction: column;
    min-height: 170px;
    align-self: stretch;
    padding: 24px 16px;
    justify-content: space-between;
    > div:first-child {
      margin-left: auto;
    }

    img {
      margin-left: auto;
      height: 100%;
      width: 100%;
    }
  }
`

const ProfileButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;

  button {
    cursor: pointer;
    background-color: var(--primary-lighter);
    border: unset;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: var(--border-radius);

    &:first-child {
      margin-bottom: 16px;
    }
  }
`

const InterestContainer = styled.div`
  display: flex;
  flex: 1 1 30% !important;
  justify-content: flex-start;
  align-items: center;
  max-width: unset !important;

  > div {
    margin: 12px 6px;
  }
`

const ProfileForm = () => {
  // const [profilePic, setProfilePic] = React.useState(false)
  const [tab, setTab] = React.useState('info')
  const [interest, setInterest] = React.useState('')
  const [savedInterests, setSavedInterests] = React.useState([])
  const [changePassOpen, setChangePassOpen] = React.useState(false)
  const [cancelSubscriptionOpen, setCancelSubscriptionOpen] =
    React.useState(false)

  const [updateSubscriptionOpen, setUpdateSubscriptionOpen] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [resent, setResent] = React.useState(0)
  const [saving, setSaving] = React.useState(false)
  const [token, setToken] = React.useState('')

  const handleInterest = ev => setInterest(ev.target.value)

  const [student, setStudent] = useState(null)


  useEffect(() => {
    if (!user) {
      return
    }
    Api.getInstance().getStudents().then(response => {
      setStudent(response.find(s => {
        return s.email === user.email
      }))
    }).catch(e => {

    }).finally(() => {
    })

  }, [user])

  const handleInterestSave = ev => {
    if (ev.key === 'Enter') {
      if (savedInterests.length >= 10) {
        return
      }
      setSavedInterests(prev => [...prev, interest])
      setInterest('')
    }
  }

  const handleInterestRemove = name => {
    const filtered = savedInterests.filter(i => i !== name)
    setSavedInterests(filtered)
  }

  React.useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      const u = JSON.parse(window.localStorage.getItem('user'))
      setUser(u)
      setToken(token)
      let user = (
        await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)
      ).data
      setUser(user)
      setSavedInterests(user.interests || [])
    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/'
    }
  }, [])

  const onChangeUserField = e => {
    let u = { ...user }
    if (e.target) {
      u[e.target.id] = e.target.value
    } else {
      //      u[e.id] = e.value;
    }
    setUser(u)
  }

  const saveUser = async () => {
    setSaving(true)
    const token = window.localStorage.getItem('token')
    let data = { ...user, token }
    data.interests = savedInterests

    let u = (await axios.post(`${config.API_BASE_URL}/users/update`, data)).data
    window.localStorage.setItem('user', JSON.stringify(u))
    setSaving(false)
    window.location.reload()
  }

  const onClickImage = () => {
    document.getElementById('selectImage').click()
  }

  const fileSelectHandler = image => {
    let u = { ...user }
    if (!image) {
      u.imageData = null
      setUser(u)
    }

    const reader = new FileReader()

    reader.readAsDataURL(image)

    reader.onloadend = function(e) {
      u.imageData = reader.result
      setUser(u)
    }
  }

  const image =
    user.imageData ||
    `${config.CDN_BASE_URL}/${user.image || DEFAULT_PROFILE_IMAGE}`
  let country = null
  getOptions('country').map(c => {
    if (user.country === c.value) {
      country = c
    }
  })
  let industry = null
  getOptions('industry').map(c => {
    if (user.industry === c.value) {
      industry = c
    }
  })
  let code = null
  PHONE_NUMBER_CODES.map(c => {
    if (user.code === c.value) {
      code = c
    }
  })

  const resendVerificationEmail = async e => {
    e.preventDefault()
    try {
      setResent(1)
      const token = window.localStorage.getItem('token')
      let u = (
        await axios.post(`${config.API_BASE_URL}/users/sendVerificationEmail`, {
          token,
        })
      ).data
      window.localStorage.setItem('user', JSON.stringify(u))
      setResent(2)
    } catch (e) {
      setResent(3)
    }
  }

  const cancelSubscription = async data => {
    try {
      const token = window.localStorage.getItem('token')
      let u = (
        await axios.post(`${config.API_BASE_URL}/users/subscription/cancel`, {
          ...data,
          token,
        })
      ).data
      window.location.reload()
    } catch (e) {
      alert('An error has occurred. Please try again later.')
    }
  }

  let { subscribed } = user
  return (
    <>
      <Container>
        <input
          id='selectImage'
          hidden
          type='file'
          onChange={e => fileSelectHandler(e.target.files[0])}
        />

        <FixedContainer>
          <HeadingsContainer>
            <div>
              <StyledH2 inverted>THIS IS</StyledH2>
              <StyledH2>YOUR PROFILE</StyledH2>
            </div>
            <div>
              {/* JUST FOR TESTING BY ALBERT */}
              <h6>
                {getTranslation('Account status', 'Account status')}:{' '}
                {subscribed ? (
                  <span>
                    {getTranslation('account_status_trustee', 'A Trustee')}{' '}
                    <ShieldGreen />
                  </span>
                ) : (
                  <span>
                    {getTranslation('account_status_non_member', 'Non Member')}{' '}
                    <ShieldRed />
                  </span>
                )}
              </h6>
              {!subscribed && (
                <small>
                  {getTranslation(
                    'account_description_non_member',
                    'The profile will be confirmed when you register as a trustee.',
                  )}
                </small>
              )}
              {subscribed && (
                <small>
                  {getTranslation(
                    'account_description_trustee',
                    'You are now a trustee',
                  )}
                </small>
              )}
              <Button
                secondary={subscribed}
                onClick={() => {
                  !subscribed && navigate(`/subscription`)
                  subscribed && setCancelSubscriptionOpen(true)
                }}
              >
                {subscribed ? 'Cancel Subscription' : 'Subscribe as Trustee'}
                <ArrowIcon />
              </Button>
              {subscribed &&
              <div className={'mt-1'}>
                <Button
                  secondary={subscribed}
                  onClick={() => {
                    setUpdateSubscriptionOpen(true)
                  }}
                >
                  Change Payment Method

                </Button>
              </div>
              }
              {/* <div className={'mt-1'}>
                <Button
                  secondary={true}
                  onClick={() => {
                    navigate('/challenge-submission')
                  }}
                >
                  Submit Challenge
                </Button>
              </div> */}
              <div className={'mt-1'}>
                <Button
                  secondary={true}
                  onClick={() => {
                    navigate('/challenge-list')
                  }}
                >
                  All Challenges
                </Button>
              </div>
              {/* {user.role !== 'ROLE_STUDENT' &&
              <div className={'mt-1'}>
                <Button
                  secondary={true}
                  onClick={() => {
                    navigate('/grades')
                  }}
                >
                  Student Grades
                </Button>
              </div>
              } */}
              <div className={'mt-20px d-flex flex-column'}>

                {user.role !== 'ROLE_STUDENT' &&
                <>
                  <a className='custom-link' href='/students'>
                    See all Students
                    <ArrowIcon />
                  </a>
                </>
                }
                {(user.role === 'ROLE_ADMIN' || user.role === 'ROLE_TRUSTEE_FULL'|| user.role === 'ROLE_TRUSTEE_LIMITED') &&
                <>
                  <a className='custom-link' href='/grades'>
                    Student Grades
                    <ArrowIcon />
                  </a>
                </>
                }
                {user.role === 'ROLE_STUDENT' &&
                <>
                  <a className='custom-link' href='/dashboard'>
                    My Dashboard
                    <ArrowIcon />
                  </a>
                  {student &&
                  <>
                    <a className='custom-link' href={`/student?id=${student.id}`}>
                      My Performance
                      <ArrowIcon />
                    </a>

                    <a className='custom-link' href={`/team?id=${student.teamId}`}>
                      Team Performance
                      <ArrowIcon />
                    </a>

                    <a className='custom-link' href={`/project?id=${student.teamId}`}>
                      Product Performance
                      <ArrowIcon />
                    </a>
                  </>
                  }
                </>
                }

              </div>
              <div className={'mt-60px d-flex flex-column'}>

                <a className='custom-link' href='/general-policy'>
                  General Policies
                  <ArrowIcon />
                </a>
              </div>
            </div>
          </HeadingsContainer>
          <StyledCard>
            {user.emailVerified === false && (
              <p>
                {resent === 0 && (
                  <>
                    <ShieldRed /> You have not verified your email yet.{' '}
                    <a href={'#'} onClick={resendVerificationEmail}>
                      Resend verification link
                    </a>
                  </>
                )}
                {resent === 1 && <span>Resending..</span>}
                {resent === 2 && <span>Email sent.</span>}
                {resent === 3 && (
                  <span>
                    <ShieldRed /> An error has occurred while sending the email.
                  </span>
                )}
              </p>
            )}
            <FormHeading>
              <h6>Personal Information</h6>
              <div>
                <button onClick={() => setChangePassOpen(true)}>
                  Change Password
                </button>
              </div>
            </FormHeading>
            <FormBody>
              <ProfilePicture onClick={onClickImage}>
                <CameraIcon />
                {/*{profilePic ? <ProfilePicIcon /> : <ProfilePicIcon />}*/}
                <div>
                  <img src={image} alt='profile avatar' />
                </div>
              </ProfilePicture>
              <Tabs>
                <Tab
                  activeTab={tab === 'info'}
                  aria-hidden
                  tabIndex={0}
                  onClick={() => setTab('info')}
                >
                  Profile Information
                </Tab>
                <Tab
                  activeTab={tab === 'contacts'}
                  aria-hidden
                  tabIndex={0}
                  onClick={() => setTab('contacts')}
                >
                  Contacts
                </Tab>
              </Tabs>
              <InputsContainer>
                <Input
                  label='First Name'
                  id={'firstName'}
                  onChange={onChangeUserField}
                  value={user.firstName}
                  placeholder='First Name'
                  type='text'
                />
                <Input
                  label='Last Name'
                  id={'lastName'}
                  onChange={onChangeUserField}
                  value={user.lastName}
                  placeholder='Last Name'
                  type='text'
                />
                <Input
                  label='Company'
                  id={'company'}
                  onChange={onChangeUserField}
                  value={user.company}
                  placeholder='tech Inc.'
                  type='text'
                />
                <Input
                  label='Position'
                  id={'position'}
                  onChange={onChangeUserField}
                  value={user.position}
                  placeholder='CEO'
                  type='text'
                />
                <Dropdown
                  label='Industry'
                  placeholder='Industry'
                  isSearchable={false}
                  keepValue={false}
                  id={'industry'}
                  value={industry}
                  onSelect={e => {
                    setUser({ ...user, industry: e.value })
                  }}
                  options={getOptions('industry')}
                  containerCss={css`
                    min-width: 220px;
                  `}
                />
                <Dropdown
                  label='Country'
                  placeholder='Country'
                  isSearchable={false}
                  keepValue={true}
                  id={'country'}
                  onSelect={e => {
                    setUser({ ...user, country: e.value })
                  }}
                  value={country}
                  options={getOptions('country')}
                  containerCss={css`
                    min-width: 220px;
                  `}
                />
                <Input
                  label='Calendly URL'
                  id={'calendly'}
                  onChange={onChangeUserField}
                  value={user.calendly}
                  placeholder='Calendly URL'
                  type='url'
                />
                <Input
                  label='Mentorship Product Interest'
                  placeholder='Type and press ↵'
                  type='text'
                  onChange={handleInterest}
                  value={interest}
                  onKeyUp={handleInterestSave}
                />
                <InterestContainer>
                  {savedInterests.map(i => (
                    <ClosablePill
                      key={i}
                      onClose={() => handleInterestRemove(i)}
                    >
                      {i}
                    </ClosablePill>
                  ))}
                </InterestContainer>


              </InputsContainer>
            </FormBody>
            <FormHeading>
              <h6>Contacts</h6>
            </FormHeading>
            <FormBody>
              <InputsContainer>
                <Input
                  disabled={true}
                  id='email'
                  value={user.email}
                  label='Email'
                  placeholder='example@email.com'
                  type='email'
                />
                <PhoneNumberInput
                  onCodeChange={e => {
                    setUser({ ...user, code: e.value })
                  }}
                  onPhoneChange={e => {
                    setUser({ ...user, phone: e.target.value })
                  }}
                  onChange={onChangeUserField}
                  code={code}
                  phone={user.phone}
                  id={'phone'}
                  label='Phone Number'
                  placeholder='94 441 112'
                />
                <Input
                  onChange={onChangeUserField}
                  value={user.linkedin}
                  id={'linkedin'}
                  label='Linkedin Url'
                  placeholder='Linkedin Url'
                  type='text'
                />
                <Input
                  onChange={onChangeUserField}
                  value={user.twitter}
                  id={'twitter'}
                  label='Twitter'
                  placeholder='@username'
                  type='text'
                />
              </InputsContainer>
              <br />
            </FormBody>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ width: '100%', maxWidth: '100%', marginLeft: '15px', marginRight: '15px' }}
                disabled={saving}
                onClick={saveUser}
              >
                {saving ? 'Updating...' : 'Update Profile'}
              </Button>
            </div>
          </StyledCard>

          <MobileHeadingsContainer>
            <div>
              <StyledH2 inverted>THIS IS</StyledH2>
              <StyledH2>YOUR PROFILE</StyledH2>
            </div>
            <div>
              <h6>
                Account status:{' '}
                {subscribed ? (
                  <span>
                    A Trustee <ShieldGreen />
                  </span>
                ) : (
                  <span>
                    Non member <ShieldRed />
                  </span>
                )}
              </h6>
              {!subscribed && (
                <small>
                  The profile will be confirmed when you register as a trustee.
                </small>
              )}
              <Button
                secondary={subscribed}
                onClick={() => {
                  !subscribed && navigate(`/subscription`)
                  subscribed && setCancelSubscriptionOpen(true)
                }}
              >
                {subscribed ? 'Cancel Subscription' : 'Subscribe as Trustee'}
                <ArrowIcon />
              </Button>

              <Button
                className={'mt-1'}
                secondary={true}
                onClick={() => {
                  navigate('/challenge-submission')
                }}
              >
                Submit Challenge
              </Button>
              <Button
                className={'mt-1'}
                secondary={true}
                onClick={() => {
                  navigate('/challenge-list')
                }}
              >
                All Challenges
              </Button>
            </div>
          </MobileHeadingsContainer>
          <MobileProfileImageContainer>
            <ProfilePicture onClick={onClickImage}>
              <CameraIcon />
              {/*{profilePic ? <ProfilePicIcon /> : <ProfilePicIcon />}*/}
              <div>
                <img src={image} alt='profile avatar' />
              </div>
            </ProfilePicture>
            <ProfileButtons>
              <button
                onClick={() => {
                  setChangePassOpen(true)
                }}
              >
                Change Password
              </button>
            </ProfileButtons>
          </MobileProfileImageContainer>
          <MobileStyledCard>
            <MobileFormHeading>
              <Tabs>
                <Tab
                  activeTab={tab === 'info'}
                  aria-hidden
                  tabIndex={0}
                  onClick={() => setTab('info')}
                >
                  Profile Information
                </Tab>
                <Tab
                  activeTab={tab === 'contacts'}
                  aria-hidden
                  tabIndex={0}
                  onClick={() => setTab('contacts')}
                >
                  Contacts
                </Tab>
              </Tabs>
            </MobileFormHeading>
            <MobileFormBody>
              {tab === 'info' ? (
                <InputsContainer>
                  <Input
                    label='First Name'
                    value={user.firstName}
                    id='firstName'
                    onChange={onChangeUserField}
                    placeholder='First Name'
                    type='text'
                  />
                  <Input
                    label='Last Name'
                    value={user.lastName}
                    id='lastName'
                    onChange={onChangeUserField}
                    placeholder='Last Name'
                    type='text'
                  />
                  <Input
                    label='Company'
                    value={user.company}
                    id='company'
                    onChange={onChangeUserField}
                    placeholder='tech Inc.'
                    type='text'
                  />
                  <Input
                    label='Position'
                    value={user.position}
                    id='position'
                    onChange={onChangeUserField}
                    placeholder='CEO'
                    type='text'
                  />
                  <Dropdown
                    label='Industry'
                    placeholder='Industry'
                    isSearchable={false}
                    id={'industry'}
                    onSelect={e => {
                      setUser({ ...user, industry: e.value })
                    }}
                    value={industry}
                    options={getOptions('industry')}
                    containerCss={css`
                      min-width: 220px;
                    `}
                  />
                  <Dropdown
                    label='Country'
                    placeholder='Country'
                    isSearchable={false}
                    keepValue={true}
                    id='country'
                    value={country}
                    onSelect={e => {
                      setUser({ ...user, country: e.value })
                    }}
                    options={getOptions('country')}
                    containerCss={css`
                      min-width: 220px;
                    `}
                  />

                  <Input
                    label='Calendly URL'
                    id={'calendly'}
                    onChange={onChangeUserField}
                    value={user.calendly}
                    placeholder='Calendly URL'
                    type='url'
                  />

                  <Input
                    label='Mentorship Product Interest'
                    placeholder='Type and press ↵'
                    type='text'
                    onChange={handleInterest}
                    value={interest}
                    onKeyDown={handleInterestSave}
                  />
                  <InterestContainer>
                    {savedInterests.map(i => (
                      <ClosablePill
                        key={i}
                        onClose={() => handleInterestRemove(i)}
                      >
                        {i}
                      </ClosablePill>
                    ))}
                  </InterestContainer>
                </InputsContainer>
              ) : (
                <InputsContainer>
                  <Input
                    value={user.email}
                    id={'email'}
                    onChange={onChangeUserField}
                    label='Email'
                    placeholder='example@email.com'
                    type='email'
                  />
                  <PhoneNumberInput
                    onCodeChange={e => {
                      setUser({ ...user, code: e.value })
                    }}
                    onPhoneChange={e => {
                      setUser({ ...user, phone: e.target.value })
                    }}
                    onChange={onChangeUserField}
                    code={code}
                    phone={user.phone}
                    label='Phone Number'
                    placeholder='94 441 112'
                  />
                  <Input
                    label='Linkedin'
                    placeholder='Linkedin Url'
                    type='text'
                    id={'linkedin'}
                    value={user.linkedin}
                    onChange={onChangeUserField}
                  />
                  <Input
                    label='Twitter'
                    placeholder='@username'
                    type='text'
                    id={'twitter'}
                    value={user.twitter}
                    onChange={onChangeUserField}
                  />
                </InputsContainer>
              )}
              <Button
                style={{ width: '100%', maxWidth: '720px' }}
                disabled={saving}
                onClick={saveUser}
              >
                {saving ? 'Updating...' : 'Update Profile'}
              </Button>
            </MobileFormBody>
          </MobileStyledCard>
          <Modal
            isOpen={cancelSubscriptionOpen}
            contentLabel={'Cancel Subscription'}
            className='react_modal_centered'
            overlayClassName='react_modal_register_overlay'
            shouldFocusAfterRender={true}
          >
            <SubscriptionCard
              titles={[
                { content: 'CANCEL', inverted: true },
                {
                  style: 'h3',
                  content: 'SUBSCRIPTION',
                  inverted: false,
                },
              ]}
              onClose={() => setCancelSubscriptionOpen(false)}
              isLeft={true}
            >
              <CancelSubscription
                user={user}
                onNo={() => setCancelSubscriptionOpen(false)}
                onSubmit={cancelSubscription}
              />
            </SubscriptionCard>
          </Modal>
        </FixedContainer>
      </Container>
      <Modal
        isOpen={changePassOpen}
        contentLabel={'Change Password'}
        ariaHideApp={false}
        className='react_modal_register'
        overlayClassName='react_modal_register_overlay'
        closeTimeoutMS={1000}
        shouldFocusAfterRender={false}
      >
        <ChangePassword
          onClose={() => setChangePassOpen(false)}
          onFinish={() => setChangePassOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={updateSubscriptionOpen}
        contentLabel={'Change Payment Method'}
        className='react_modal_centered'
        overlayClassName='react_modal_register_overlay'
        shouldFocusAfterRender={true}
      >
        <SubscriptionCard
          titles={[
            { content: 'UPDATE', inverted: true },
            {
              style: 'h3',
              content: 'Payment Method',
              inverted: false,
            },
          ]}
          onClose={() => setUpdateSubscriptionOpen(false)}
          isLeft={true}
        >
          <UpdateSubscription />
        </SubscriptionCard>
      </Modal>
    </>
  )
}

export default ProfileForm
